*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

.AuthImage {
  background-image: url("./Images/Waves.svg");
  background-position: bottom;
  background-size: cover;
  background-color: #072655;
}

.Card:hover {
  border: solid 1px rgba(0, 0, 0, 0.5);
}

.Comment {
  border: solid 1px #616161;
}

.Comment:hover {
  border: solid 1px rgba(255, 255, 255, 1);
}

.myLineDecoration {
  background-color: rgba(76, 175, 80, 0.2); /* Light green background */
}

.myHoveredLineDecoration {
  background-color: rgba(15, 82, 186, 0.25); /* Muted Blue for hovered */
  border-left: solid 2px white;
}

.commentType-CodeQuality {
  background-color: rgba(229, 57, 53, 0.25); /* Red */
}

.commentType-DocumentationQuality {
  background-color: rgba(30, 136, 229, 0.25); /* Blue */
}

.commentType-BugDetection {
  background-color: rgba(67, 160, 71, 0.25); /* Green */
}

.commentType-SecurityAnalysis {
  background-color: rgba(142, 36, 170, 0.25); /* Purple */
}

.commentType-TestCoverage {
  background-color: rgba(251, 140, 0, 0.25); /* Orange */
}

.commentType-PerformanceAnalysis {
  background-color: rgba(0, 172, 193, 0.25); /* Cyan */
}

.commentType-AccessibilityAnalysis {
  background-color: rgba(253, 216, 53, 0.25); /* Yellow */
}

.commentType-ArchitectureAnalysis {
  background-color: rgba(109, 76, 65, 0.25); /* Brown */
}

.commentType-ContinuousIntegration {
  background-color: rgba(57, 73, 171, 0.25); /* Indigo */
}

.Landing-Background {
  background-image: url("./Images/LandingBg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.Doc-Background {
  background-image: url("./Images/DocBackground.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.Pricing-Background {
  background-image: url("./Images/PricingBackground.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.Feature-Background {
  background-image: url("./Images/FeatureBackground.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.Footing-Background {
  background-image: url("./Images/FootingBackground.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.Landing-Card {
  transition: transform 0.3s ease;
}

.Landing-Card:hover {
  border-color: #0f52ba;
  transform: scale(1.05);
}

.Landing-Demo {
  background: url("./Images/DemoBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Landing-Chrome {
  background: url("./Images/ChromeBackground.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Landing-Card-Wrap {
  transition: transform 0.3s ease;
  cursor: default;
}

.Landing-Card-Wrap:hover {
  transform: scale(1.05);
}

@keyframes bg {
  0% {
    background-size: 0 3px, 3px 0, 0 3px, 3px 0;
  }
  25% {
    background-size: 100% 3px, 3px 0, 0 3px, 3px 0;
  }
  50% {
    background-size: 100% 3px, 3px 100%, 0 3px, 3px 0;
  }
  75% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 0;
  }
  100% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
  }
}

.borderTrace {
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #003366, #0066cc),
    linear-gradient(to bottom, #003366, #0066cc),
    linear-gradient(to right, #003366, #0066cc),
    linear-gradient(to bottom, #003366, #0066cc);
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-size: 0 3px, 3px 0, 0 3px, 3px 0;
}

.borderTrace:hover {
  animation: bg 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}

.Icon {
  background: radial-gradient(
    132.8% 132.8% at 50% 50%,
    #0c3b8c 0%,
    rgba(12, 59, 140, 0) 100%
  );
}

@keyframes pulse {
  0%,
  100% {
    background-color: #1f1f1f;
    box-shadow: 0 0 0px #0f52ba;
  }
  50% {
    background-color: #0f52ba;
    box-shadow: 0 0 10px #0f52ba, 0 0 15px #0f52ba, 0 0 20px #0f52ba;
  }
}

.pulsing-divider {
  animation: pulse 5s infinite;
}

.gradient-border-wrapper {
  display: inline-block; /* Or 'block' depending on your layout */
  padding: 2px; /* This will be your border size */
  background: linear-gradient(
    to right,
    #ed25c1,
    #a74fff,
    #f096ff,
    #7521bc,
    #7521bc,
    #ff96e2,
    #874fff,
    #4d25ed
  );
  border-radius: 5px; /* Match this value to your Button's border-radius */
  margin: -2px; /* Adjust the margin to counteract the padding if needed */
}

/* Add this class in your CSS file */
.leftAlignedButtonText {
  justify-content: flex-start !important; /* The use of !important is to ensure override of default styles */
}

.LinkAccountBorder {
  animation: pulse 5s infinite;
  box-shadow: 0 0 10px rgba(0, 100, 0, 0.8), 0 0 20px rgba(0, 100, 0, 0.6),
    0 0 30px rgba(0, 100, 0, 0.4), 0 0 40px rgba(0, 100, 0, 0.2);
}

@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 0px rgba(0, 100, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 100, 0, 0.8), 0 0 15px rgba(0, 100, 0, 0.6),
      0 0 20px rgba(0, 100, 0, 0.4), 0 0 25px rgba(0, 100, 0, 0.2);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 2s forwards;
}

.line-highlight {
  transition: background-color 2.25s ease-in-out; /* Smooth transition for 2 seconds */
}

/* In your App.css file */
.transition-width {
  transition: width 2.5s ease-in-out; /* Increase the duration to 5 seconds */
}

.grain {
  /* Other styles */
  background-image: repeating-linear-gradient(
      0deg,
      #000 0px,
      #000 1px,
      transparent 1px,
      transparent 2px
    ),
    repeating-linear-gradient(
      90deg,
      #000 0px,
      #000 1px,
      transparent 1px,
      transparent 2px
    );

  /* Set the size of the noise texture */
  background-size: 2px 2px;

  /* Lower the opacity to make the grain subtler */
  opacity: 0.5;

  /* Use 'absolute' positioning to overlay it on your element */
  position: absolute;
  inset: 0;
}

.card::after {
  content: "";
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 600'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-size: 182px;
  opacity: 0.15;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px -5px transparent;
  }
  50% {
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 5px -5px transparent;
  }
}